import { Routes } from "@angular/router";

const defaultLang = "it";

export const routes: Routes = [
  {path : '', pathMatch : 'full', redirectTo : `home`},
  {
    path: 'home',
    loadComponent: () => import('./components/home/home.component').then((c) => c.HomeComponent)
  },
  {
    path: 'restaurant-details/:id',
    loadComponent: () => import('./components/components-details/restaurant-details/restaurant-details.component').then((c) => c.RestaurantDetailsComponent)
  },
  {
    path: 'dish-details/:id',
    loadComponent: () => import('./components/components-details/dish-details/dish-details.component').then((c) => c.DishDetailsComponent)
  },
  {
    path: 'chef-details/:id',
    loadComponent: () => import('./components/components-details/chef-details/chef-details.component').then((c) => c.ChefDetailsComponent)
  },
  {
    path:'experience-details/:id',
    loadComponent: () => import('./components/components-details/experience-details/experience-details.component').then((c)=>c.ExperienceDetailsComponent)
  },
  {
    path: 'restaurants/all',
    loadComponent: () => import('./components/dynamic-data-card/dynamic-data-card.component').then((c) => c.DynamicDataCardComponent)
  },
  {
    path: 'dishes/all',
    loadComponent: () => import('./components/dynamic-data-card/dynamic-data-card.component').then((c) => c.DynamicDataCardComponent)
  },
  {
    path: 'protagonists/all',
    loadComponent: () => import('./components/dynamic-data-card/dynamic-data-card.component').then((c) => c.DynamicDataCardComponent)
  },
  {
    path: 'experiences/all',
    loadComponent: () => import('./components/dynamic-data-card/dynamic-data-card.component').then((c) => c.DynamicDataCardComponent)
  },
  {
    path: 'register',
    loadComponent: () => import('./components/register/register.component').then((c) => c.RegisterComponent)
  },
  {
    path: 'login',
    loadComponent: () => import('./components/login/login.component').then((c) => c.LoginComponent)
  },
  {
    path:'confirmation-account',
    loadComponent: () => import('./components/confirmation-account/confirmation-account.component').then((c) => c.ConfirmationAccountComponent)
  },
  {
    path: 'favorites',
    loadComponent: () => import('./components/favorites/favorites.component').then((c) => c.FavoritesComponent)
  },
  {
    path: 'reservations',
    loadComponent: () => import('./components/reservations/reservations.component').then((c) => c.ReservationsComponent)
  }

];
