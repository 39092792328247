import { Component } from '@angular/core';
import { LoadingService } from "../../services/loading.service";
import { NgIf } from "@angular/common";

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [
    NgIf
  ],
  template: `
    <div *ngIf="this.loadingSpinner.getLoading()" class="overlay">
      <div class="load-container d-flex justify-content-center">
      <div class="spinner-border spinner-border-lg mt-5" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      </div>
   </div>
  `,
  styleUrl: './loading.component.scss'
})
export class LoadingComponent {

  constructor( public loadingSpinner: LoadingService) { }

}