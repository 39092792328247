import {inject, Injectable} from "@angular/core";
import { LoadingService } from "../../services/loading.service";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { finalize, Observable } from "rxjs";

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  private totalHttpRequests = 0;

  private readonly loadingService = inject(LoadingService);

   intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.totalHttpRequests++;
    this.loadingService.setLoading(true);
    return next.handle(request).pipe(
      finalize(() => {
        this.totalHttpRequests--;
        if (this.totalHttpRequests === 0) {
          this.loadingService.setLoading(false);
        }
      })
    )
  }

}
