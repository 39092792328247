import { ApplicationConfig, LOCALE_ID, importProvidersFrom, isDevMode } from '@angular/core';
import { 
  provideRouter,
  withInMemoryScrolling,
  InMemoryScrollingFeature,
  InMemoryScrollingOptions 
} from '@angular/router';
import { routes} from "./app.routes";
import { provideClientHydration } from '@angular/platform-browser';
import { provideServiceWorker } from '@angular/service-worker';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, provideHttpClient, withFetch } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ObserversModule } from "@angular/cdk/observers";
import { LanguageInterceptor } from "./guard/core/language.interceptor";
import { LoadingInterceptor } from "./guard/core/loading.interceptor";
import { AuthenticationInterceptor } from "./guard/core/authentication.interceptor";
import { CommonModule } from "@angular/common";
import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from "@abacritt/angularx-social-login";
import { provideToastr } from "ngx-toastr";
import { OAuthModule } from 'angular-oauth2-oidc';
import { CookieService } from "ngx-cookie-service";
import '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en';
import { ErrorCatchingInterceptor } from "./guard/core/error-catching.interceptor";

registerLocaleData(localeIt , localeEn);

export function HttpLoaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

export function LocaleIdFactory(translate: TranslateService): string {
  const lang = translate.store.defaultLang || 'en';
  return lang === 'it' ? 'it-IT' : 'en-EN';
}
// Added configuration for in-memory scrolling
const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, inMemoryScrollingFeature),
    provideClientHydration(),
    provideHttpClient(withFetch()),
    provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    }),
    provideToastr({
      timeOut: 4000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      progressBar: true
    }),
    importProvidersFrom([
      BrowserAnimationsModule,
      HttpClientModule,
      ObserversModule,
      CommonModule,
      SocialLoginModule,
      OAuthModule,
      TranslateModule.forRoot({
        defaultLanguage: 'it',
        loader: {
          provide: TranslateLoader,
          useFactory: (HttpLoaderFactory),
          deps: [HttpClient]
        }
      }),
    ]),
    LanguageInterceptor,
    AuthenticationInterceptor,
    LoadingInterceptor,
    CookieService,
    {provide : HTTP_INTERCEPTORS, useClass: ErrorCatchingInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    // TEST FOR CALENDAR LANGUAGE

    {
      provide: LOCALE_ID,
      useFactory: (LocaleIdFactory),
      deps: [TranslateService]
    },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorCatchingInterceptor, multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '74084838027-palpphvni7mp4dbto6c75v3ncc2ike7o.apps.googleusercontent.com'
            )
          },
        ],
        onError: (err: any) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
};
