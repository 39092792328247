import { Component, OnInit } from '@angular/core';
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { ObserversModule } from "@angular/cdk/observers";
import { RouterOutlet } from "@angular/router";
import { initFlowbite } from 'flowbite';
import { AuthenticationService } from "./services/authentication.service";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HomeComponent } from "./components/home/home.component";
import { LoadingComponent } from './components/loading/loading.component';
import { TranslateService } from "@ngx-translate/core";
import {StorageKey} from "../environments/environment.storage";

@Component({
  selector: 'app-root',
  standalone: true,
  host: { ngSkipHydration: 'true' },
  imports: [
    CommonModule,
    HttpClientModule,
    ObserversModule,
    RouterOutlet,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    LoadingComponent
  ],
  providers: [
    AuthenticationService,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})

export class AppComponent implements OnInit {

  constructor(translate: TranslateService) {
    let lang = localStorage.getItem(StorageKey.lang);
    if (lang) {
      translate.use(lang);
    } else {
      translate.use('it');
      localStorage.setItem(StorageKey.lang, 'it');
    }
  }


  ngOnInit(): void {
    initFlowbite();
  }

}
