import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthenticationService } from "../../services/authentication.service";
import { Observable } from "rxjs";
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  private readonly authenticationService = inject(AuthenticationService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authenticationService?.isAuthenticated) {
      const TOKEN = this.authenticationService.token ?? '';
      const API_URL = req.url.startsWith(environment.endPoint);
      // if (API_URL) {
      //   // const REQUEST = req.clone({
      //   //   setHeaders: {
      //   //     Cookie: `${TOKEN}`,
      //   //   },
      //   // });
      //   return next.handle(REQUEST);
      // }
    }
    return next.handle(req.clone());
  }

}
