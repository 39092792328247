import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { from, lastValueFrom, Observable } from "rxjs";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../services/authentication.service";
import {StorageKey} from "../../../environments/environment.storage";

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  constructor( private router: Router, private authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(request, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    const langRequestToken = req.clone({
      setHeaders: {
        "Content-Language": localStorage.getItem(StorageKey.lang) == 'it' ? "1" : "2"
      }
    })
    return  lastValueFrom(next.handle(langRequestToken));
  }

}
