<div class="bg-quovo-gray h-full">
    <app-navbar></app-navbar>
    <app-loading></app-loading>
    <div class="flex flex-col bg-quovo-gray h-screen">
        <div class="flex">
            <app-navbar></app-navbar>
        </div>
        <div class="w-full mt-18 md:mt-[72px] lg:mt-[96px] h-full">
            <router-outlet></router-outlet>
            <app-footer></app-footer>
        </div>
    </div>
</div>