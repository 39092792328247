import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {EMPTY, Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {AuthenticationService} from "../../services/authentication.service";


@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {

  private readonly router = inject(Router);
  private readonly toastr = inject(ToastrService)

  private readonly authenticationService = inject(AuthenticationService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(err => {
      if ([400, 404 , 403, 415 , 500].includes(err.status)) {
        this.router.navigate(['home']);
      }
      if(err.status === 401) {
        this.authenticationService.restoreUserData();
      }
      if(err.status === 405) {
        this.toastr.error(err.statusText, err?.status.toString());
        return EMPTY;
      }
      if (err.status === 504) {
        this.toastr.error('Bad Gatway', 'Error 504');
        return EMPTY;
      }

      const errror = err.error.message || err.statusText;
      return throwError(() => errror);
    }))
  }

}
